<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"
    import Svelecte from "svelecte/src/Svelecte.svelte"
    import {createEventDispatcher} from "svelte"
    import Translator from "bazinga-translator";

    const dispatch = createEventDispatcher()

    export let entry: FormEntry
    export let i18n = {
        collapsableSelected: Translator.trans("select_i18n.collapsableSelected"),
        collapsableAvailable: Translator.trans("select_i18n.collapsableAvailable"),
        empty: Translator.trans("select_i18n.empty"),
        nomatch: Translator.trans("select_i18n.nomatch"),
        max: num => Translator.trans("select_i18n.max")+`: ${ num }`,
        fetchBefore: Translator.trans("select_i18n.fetchBefore"),
        fetchQuery: (minQuery, inputLength) => `${Translator.trans("select_i18n.fetch.write")} ${ minQuery > 1 && minQuery > inputLength
                ? `${Translator.trans("select_i18n.fetch.more")} ${ minQuery - inputLength } ${Translator.trans("select_i18n.fetch.chars")} `
                : '' }${Translator.trans("select_i18n.fetch.to_search")}`,
        fetchInit: Translator.trans("select_i18n.fetchInit"),
        fetchEmpty: Translator.trans("select_i18n.fetchEmpty"),
        collapsedSelection: count => `${Translator.trans("select_i18n.collapsedSelection")}: ${ count }`,
        createRowLabel: value => `${Translator.trans("select_i18n.createRowLabel")}: '${ value }'`,
    }

    let containerEl: HTMLElement

    const handleChange = (item): void => {
        dispatch("change", item.detail)

        const select = containerEl?.querySelector("select")
        if (select) {
            select.dispatchEvent(new Event("change", {
                composed: true,
                bubbles: true,
                cancelable: true,
            }))
        }
    }

    const handleCreateItem = (e: CustomEvent<{ $created: boolean, label: string, value: string }>): void => {
        if (e.detail.$created) {
            entry.value = e.detail.value
            entry.choices.push(e.detail as any)
        }
    }
</script>

{#if !(entry.disabled && entry.value === "")}
    <div {...entry.rowAttr} bind:this={containerEl} class="flex flex-col {entry.rowAttr?.class ?? ''}">
        {#if entry.label !== null}
            <label class="font-bold text-neutral-800 relative" {...entry.labelAttr}>
                {@html entry.label}
                {#if !entry.disabled && entry.required}
                    <span class="absolute ml-1 text-red-800">*</span>
                {/if}
            </label>
        {/if}

        <Svelecte
                placeholder={entry.disabled ? '' : entry.attr?.placeholder ?? Translator.trans("select_placeholder")}
                virtualList
                {...entry.attr}
                bind:value={entry.value}
                class="svelecte-control shadow-sm mt-1 {entry.attr?.class ?? ''} {entry.disabled ? 'msd-svelecte-disabled' : ''}"
                clearable={true}
                disabled={entry.disabled}
                inputId={entry.id}
                labelField="label"
                multiple={entry.multiple}
                name={entry.fullName}
                on:change={handleChange}
                on:createoption={handleCreateItem}
                options={entry.choices}
                style="
                    --sv-min-height: 36px;
                    --sv-bg: white;
                    --sv-border: none;
                    --sv-active-border: none;
                "
                valueField="value"
                collapseSelection={entry.attr?.collapsable}
                {i18n}
        />

        <FormTypeErrors errors={entry.errors}/>

        {#if entry.help}
            <small class="mt-3">{@html entry.help}</small>
        {/if}
    </div>
{/if}
